<template>
    <div>
      <v-layout wrap justify-center>
        <v-snackbar v-model="showSnackBar" color="black">
          <v-layout wrap justify-center>
            <v-flex text-left class="align-self-center">
              <span style="color: white">
                {{ msg }}
              </span>
            </v-flex>
            <v-flex text-right>
              <v-btn small :ripple="false" text @click="showSnackBar = false">
                <v-icon style="color: white">mdi-close</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-snackbar>
        <vue-element-loading
          :active="appLoading"
          :is-full-screen="true"
          background-color="#FFFFFF"
          color="#283e51"
          spinner="spinner"
        />
      </v-layout>
      <v-layout wrap justify-center>
        <v-flex xs7 pt-8 text-left>
          <span style="font-size: 25px;font-weight: bolder;"
            >Pre-PENDING APPLICATIONS
          </span>
        </v-flex>
        <v-flex xl3 lg2 md3 pt-6 pr-4 sm3 xs12 align-self-center>
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                clearable
                dense
                outlined
                readonly
                v-model="fromDate"
                v-bind="attrs"
                background-color="white"
                v-on="on"
                @click:clear="from = null"
                style="color: black"
              >
                <template v-slot:append>
                  <v-icon color="black">mdi-chevron-down</v-icon>
                </template>
                <template v-slot:label>
                  <span
                    class="custom-label-text"
                    style="color: black; font-size: 14px"
                  >
                    From Date</span
                  >
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="fromDate"
              color="#13736f"
              @change="menu1 = false"
            ></v-date-picker>
          </v-menu>
        </v-flex>
        <v-flex xl3 lg2 md3 pt-6 pr-1 sm3 xs12 align-self-center>
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            max-width="290"
            elevation="0"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                clearable
                label="To Date"
                readonly
                dense
                background-color="white"
                color="#13736f"
                outlined
                v-model="toDate"
                v-bind="attrs"
                v-on="on"
                @click:clear="from = null"
              >
                <template v-slot:append>
                  <v-icon color="black">mdi-chevron-down</v-icon>
                </template>
                <template v-slot:label>
                  <span
                    class="custom-label-text"
                    style="color: black; font-size: 14px"
                  >
                    To Date</span
                  >
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="toDate"
              color="#13736f"
              @change="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-flex>
      </v-layout>
<!-- {{ ppid }} -->
      <v-layout  wrap justify-center>
        <v-flex lg12 md11 pt-3>
          <v-layout wrap>
            <!-- <v-flex lg1 :style="{ backgroundColor: item.cardColor }">
        </v-flex> -->
          <v-flex lg6  v-for="(item, i) in pendingApplicationsData" :key="i" px-5>
          <v-card outlined class="pa-3" @click="pendingApp(item)" >
            <v-layout wrap justify-center>
              <v-flex xs12>
                <v-layout wrap pa-2 class="fill-height">
                  
                  <v-flex xs4 lg2 pl-2>
                    <v-img
                      height="100%"
                      contain
                      src="../../assets/Images/ibimage12.jpg"
                    ></v-img>
                  </v-flex>
                  <v-flex xs8 lg10 pl-4>
                    <v-layout wrap justify-start>
                      <v-flex xs12 text-left pt-4 class="mainfont">
                        <v-layout wrap justify-start>
                          
        
                          <v-flex xs12 lg12 md6 text-left>
                            <span class="mainfont" style="font-size: 17px">
                              <span style="font-size: 17px;font-weight: bold;">
                                Booked Date&nbsp;:&nbsp;</span
                              >
  
                              {{ displayDate(item.fromDate) }} to {{ displayDate(item.toDate) }}
                            </span>
                          </v-flex>

                          <v-flex xs12 lg12 md6 text-left >
                            <span class="mainfont" style="font-size: 17px">
                              <span style="font-size: 17px;font-weight: bold;">
                                Count&nbsp;:&nbsp;</span
                              >
  
                              {{ item.count }} 
                            </span>
                          </v-flex>

                        </v-layout>

                    </v-flex>
                    </v-layout>


                  </v-flex>
                </v-layout>
                <v-divider></v-divider>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        </v-layout>
        </v-flex>
      </v-layout>
  
    
    </div>
  </template>
  <script>
  import axios from "axios";
  import moment from "moment";
  
  export default {
    data() {
      return {
        itemArray2: ["Vegeterian", "Non-vegetarian"],
        // cdate: moment().format("YYYY-MM-DD"),
        curIdToDelete: null,
        name: null,
        editdialog: false,
        barlist: [],
        edit: [],
        userProfileTabs: null,
        subtab: null,
        appLoading: false,
        page: 1,
        keyArray: [],
  
        pages: 0,
        dialog1: false,
        preview: null,
        tab: null,
        barlist1: [],
        dialog3: false,
        // deletedialog: false,
        approvedialog: false,
        minAmount: null,
        password: null,
        categoryarray: [],
        pendingApplicationsData:[],
        list: [],
        flag: false,
        flag2: false,
        auctionbased: [],
        currentpage: 1,
        menu1: false,
        cdate: null,
        menu2: false,
        menu3: false,
        menu4: false,
        fromDate: null,
        toDate: null,
        fromDate2: null,
        toDate2: null,
        msg: null,
        dummy:[],
        currentPage: 1,
        showSnackBar: false,
        info: [],
        dialog2: false,
        viewdialog: false,
        cardColor:"",
        priority1: ["MINISTER",
          "SPEAKER",
          "DEPUTY SPEAKER",
          "GOVERNMENT CHIEF WHIP",
          "MP",
          "MLA",
        ],
        priority2: ["HIGH COURT OF KERALA JUDGE",
          "ADVOCATE GENERAL",
          "CHIEF SECRETARY",
          "ADDITIONAL CHIEF SECRETARY/PRINCIPAL SECRETARY/SECRETARY (FORESTS AND WILDLIFE DEPARTMENT)",
          "FOREST OFFICERS ON INSPECTION",
          "FOREST OFFICER",
        ],
        priority3: ["RETIRED FOREST OFFICER",
          "OFFICER OF OTHER DEPARTMENT",
          "OFFICER OF CENTRAL GOVERNMENT",
          "OFFICER OF OTHER STATE GOVERNMENT",
          "OFFICER OF PSU",
          "GENERAL PUBLIC",
        ],
        ppid:[],
      };
    },
  
    mounted() {
      this.getList();
    },
    watch: {
      currentPage() {
        this.getList();
      },
      fromDate() {
        this.getList();
      },
      toDate() {
        this.getList();
      },
    },
    computed: {
      guestPhoneRules() {
        return [
          (v) => !!v || "Phone number is required",
          (v) => /^[0-9]{10}$/.test(v) || "Phone number must be 10 digits",
        ];
      },
      emailRules() {
        return [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ];
      },
      minDate() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const day = String(today.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      },
      formattedDate() {
        const date = new Date(this.edit.selectedDate);
        return date.toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
      },
    },
  
    methods: {

      displayDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    },

      setCurIdToDelete(id) {
        this.curIdToDelete = id;
      },
      // deleteItem() {
      //   const idToDelete = this.curIdToDelete;
  
      //   if (!idToDelete) {
      //     return;
      //   }
  
      //   axios({
      //     url: "/cancel/booking",
      //     method: "POST",
      //     headers: {
      //       token: localStorage.getItem("token"),
      //     },
      //     data: {
      //       bookingid: idToDelete,
      //     },
      //   })
      //     .then((response) => {
      //       this.appLoading = false;
      //       if (response.data.status == true) {
      //         this.msg = response.data.msg;
      //         this.showSnackBar = true;
      //         this.deletedialog = false;
      //         window.location.reload();
      //       } else {
      //         this.msg = response.data.msg;
      //         this.showSnackBar = true;
      //       }
      //     })
      //     .catch((err) => {
      //       this.appLoading = false;
      //       this.ServerError = true;
      //       console.log(err);
      //     });
      // },
      pendingApp(item) {
 
        this.$router.push({ 
            path: "/pending", 
            query: { 
                fromdate: item.fromDate,
                todate: item.toDate,
                id: item._id,
            }
        });
},


      // editItem() {
      //   axios({
      //     url: "/edit/booking",
      //     method: "POST",
      //     headers: {
      //       token: localStorage.getItem("token"),
      //     },
      //     data: {
      //       bookingid: this.edit._id,
      //       guestName: this.edit.guestDetail.guestName,
      //       guestEmailId: this.edit.guestDetail.guestEmailId,
      //       noofadults: this.edit.guestDetail.noofadults,
      //       noofchildren: this.edit.guestDetail.noofchildren,
      //       guestMobileNo: this.edit.guestDetail.guestMobileNo,
      //       referredBy: this.edit.guestDetail.referredBy,
      //       referencePersonPh: this.edit.guestDetail.referencePersonPh,
      //       mealsType: this.edit.guestDetail.mealsType,
      //       anyOtherDetails: this.edit.guestDetail.anyOtherDetails,
      //       selectedDate: this.edit.cdate,
      //       vehicleNumbers: this.edit.guestDetail.vehicleNumbers,
      //     },
      //   })
      //     .then((response) => {
      //       this.appLoading = false;
      //       if (response.data.status == true) {
      //         this.msg = response.data.msg;
      //         this.showSnackBar = true;
      //         this.editdialog = false;
      //         window.location.reload();
      //       } else {
      //         this.msg = response.data.msg;
      //         this.showSnackBar = true;
      //       }
      //     })
      //     .catch((err) => {
      //       this.appLoading = false;
      //       this.ServerError = true;
      //       console.log(err);
      //     });
      // },

      editItem() {
  

  axios({
    url: "/edit/booking",
    method: "POST",
      headers: {
      token: localStorage.getItem("token"),
      },
    data: {
      bookingid: this.edit._id,
      reqRooms: this.edit.guestDetail.reqRooms,
      fromDate: this.edit.guestDetail.fromDate,
      toDate: this.edit.guestDetail.toDate,
      guestEmailId: this.edit.guestDetail.guestEmailId,
      noofadults: this.edit.guestDetail.noofadults,
      noofchildren: this.edit.guestDetail.noofchildren,
      guestMobileNo: this.edit.guestDetail.guestMobileNo,
      
      
      anyOtherDetails: this.edit.guestDetail.anyOtherDetails,
    

      idNo: this.edit.guestDetail.idNo,
      idtype: this.edit.guestDetail.idtype,
    },
  })
    .then((response) => {
      this.appLoading = false;
      if (response.data.status == true) {
        this.msg = response.data.msg;
        this.showSnackBar = true;
        this.editdialog = false;
        window.location.reload();
      } else {
        this.msg = response.data.msg;
        this.showSnackBar = true;
      }
    })
    .catch((err) => {
      this.appLoading = false;
      this.ServerError = true;
      console.log(err);
    });
},
      approveItem(itemid) {
        axios({
          url: "/admin/approval",
          method: "POST",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            bookingid: itemid,
            status: "yes",
            reason: "",
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status == true) {
                this.msg = response.data.msg;
                this.showSnackBar = true;
              this.approvedialog = false;
              setTimeout(() => {
              location.reload();
              }, 2000);
        
              // this.showSnackBar = true;
              // this.approvedialog = false;
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.approvedialog = false;
              
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
  
      getInfo(id) {
        axios({
          method: "POST",
          url: "/single/booking",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            bookingid: id,
          },
        })
          .then((response) => {
            this.info = response.data.data;
          })
          .catch((err) => {
            this.ServerError = true;
            console.log(err);
          });
      },
      formatDate(item) {
        var dt = new Date(item);
        var year = dt.getFullYear();
        dt = dt.toString();
        var strTime = dt.slice(4, 10) + " " + year;
        return strTime;
      },
  
  //     formatDate(item) {
  //     // Parse the input date string to a JavaScript Date object
  //     var dt = new Date(item);
  
  //     // Increment the time by 5 hours and 30 minutes
  //     dt.setHours(dt.getHours() + 5);
  //     dt.setMinutes(dt.getMinutes() + 30);
  
  //     // Extract year from the modified date
  //     var year = dt.getFullYear();
  
  //     // Format the modified date to a custom string
  //     var month = ('0' + (dt.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() is zero-based
  //     var day = ('0' + dt.getDate()).slice(-2);
  //     var strTime = month + ' ' + day + ' ' + year;
  
  //     return strTime;
  // },
  
  
  
    //   getList() {
    //     this.appLoading = true;
    //     axios({
    //       method: "POST",
    //       url: "/pending/bookings",
    //       headers: {
    //         token: localStorage.getItem("token"),
    //       },
    //       data: {
    //         status: this.status,
    //         categoryId: this.categoryId,
    //         // page: this.currentPage,
    //         fromDate: this.fromDate,
    //         toDate: this.toDate,
    //         // limit: 10,
    //       },
    //     })
    //       .then((response) => {
    //         this.dummy = response.data.pendingApplications;
    //         this.list = response.data.pendingApplications.map((item) => {
    //           const date = new Date(item.selectedDate);
    //           item.selectedDate = date.toLocaleDateString();
    //           item.cdate = moment(item.selectedDate).format("YYYY-MM-DD");
    //           return item;
    //         });
    //         this.totalData = response.data.totalLength;
    //         this.selectedDatesArray = this.list.map((item) => item.selectedDate);
    //         this.pages = Math.ceil(this.totalData / response.data.limit);
    //         this.appLoading = false;
    //         for(let i=0; i<this.list.length; i++)
    //         {
    //           if(this.priority1.includes(this.list[i].priority))
    //           {
    //            this.list[i].cardColor="#E01B11"
    //           //  this.cardColor="#a83f2f"
    //           }
    //           else if(this.priority2.includes(this.list[i].priority))
    //           {
    //            this.list[i].cardColor="#3d85c6"
  
    //             // this.cardColor="#154bd4"
    //           }
    //           else if(this.priority3.includes(this.list[i].priority))
    //           {
    //            this.list[i].cardColor="#8fce00"
              
    //             // this.cardColor="#32a852"
    //           }
    //           else{
    //            this.list[i].cardColor=""
    //            }
    //         }
    //       })
    //       .catch((err) => {
    //         this.ServerError = true;
    //         console.log(err);
    //       });
    //   },
    getList() {
    this.appLoading = true;
    axios({
        method: "POST",
        url: "/pending/bookings",
        headers: {
            token: localStorage.getItem("token"),
        },
        data: {
            status: this.status,
            categoryId: this.categoryId,
            fromDate: this.fromDate,
            toDate: this.toDate,
            id: this.ppid,
        },
    })
    .then((response) => {
        // Handle the main list as before
        this.dummy = response.data.pendingApplications;
        this.list = response.data.pendingApplications.map((item) => {
            const date = new Date(item.selectedDate);
            item.selectedDate = date.toLocaleDateString();
            item.cdate = moment(item.selectedDate).format("YYYY-MM-DD");
            return item;
        });
        this.totalData = response.data.totalLength;

        // Initialize ppid as an empty array of arrays
        this.ppid = [];

        // Initialize an array to store _id values from applications array
        this.applicationIds = [];

        // Extract fromDate, toDate, and count from pendingApplications
        this.pendingApplicationsData = response.data.pendingApplications.map((pendingApp) => {
            const fromDate = pendingApp._id.fromDate;
            const toDate = pendingApp._id.toDate;
            const count = pendingApp.count;

            // Extract _id values from applications array and push them to applicationIds array
            pendingApp.applications.forEach((app) => {
                this.ppid.push(app._id);
            });

            return { fromDate, toDate, count };
        });

        this.selectedDatesArray = this.list.map((item) => item.selectedDate);
        this.pages = Math.ceil(this.totalData / response.data.limit);
        this.appLoading = false;

        // Use this.pendingApplicationsData and this.ppid for displaying the required information
    })
    .catch((err) => {
        this.ServerError = true;
        console.log(err);
    });
},






    },
  };
  </script>
  
  <!-- <style>
  .custom-button {
          margin-top: 10px;
          background-color: #349e4e;
          border-color: #349e4e;
          filter: brightness(70%); /* Adjust the percentage to reduce brightness */
      }
  </style> -->
  
  <style scoped>
  .pdf-button {
    margin-right: 10px; /* Adjust the value as needed for the desired gap */
  }
  </style>
  